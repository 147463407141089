@import './variables.scss';

@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Exo:wght@300;400;500;600;700&display=swap');

html, body,
div, p {

    margin: 0;
    padding: 0;

}

html {
    font-family: 'Exo', sans-serif;
    font-weight: 600;
    font-size: 10pt;

    @media (min-width: 400px) {
        font-size: 12px;
    }

    @media (min-width: 768px) {
        font-size: 17pt;
    }
}

body {
    min-width: 375px;
    margin-left: calc(100vw - 100%);
}

button, input {
    font-family: 'Exo', sans-serif;
}
